<template>
  <div class="card mb-5 mb-xl-12">
    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
      
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Sıkça Sorulan Sorular</h3>

        
      </div>      
    </div>
    
    
    <div id="kt_account_profile_details" class="collapse show" v-if="faqs">

        <div id="package-detail" v-if="showDetail">
            <div class="card-body border-top p-9">
                
                <FaqDetailForm :selectedFaq="selectedFaq" :mode="mode" v-if="selectedFaq" />
            </div>

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button type="reset" @click.prevent="cancelForm()" class="btn btn-white btn-active-light-primary me-2">Vazgeç</button>

                <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" @click.prevent="updateFaq()" class="btn btn-primary">
                    <span class="indicator-label"> Kaydet </span>
                    <span class="indicator-progress">
                    Lütfen bekleyin...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
            <!--end::Actions-->
        </div>


        <div class="card-body border-top p-9" v-show="!showDetail">
            <div class="table-responsive">
                <a href="" @click.prevent="addFaq()" class="btn btn-primary float-right">Yeni Soru Ekle</a>
              <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr class="fw-bolder">
                    <th>Sıra No</th>
                    <th>Soru</th>
                    <th>Durum</th>                    
                    <th>İşlem</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in faqs" :key="i">
                    <td>{{item.orderNo}}</td>
                    <td>{{item.question}}</td>
                    <td><span :class="'badge badge-light-'+statusses.class[item.status]">{{statusses.label[item.status]}}</span></td>                    
                    <td><ActionsMenu :item="item" :packagekey="i" @edit="editFaq($event)" @remove="removeFaq($event)" /></td>
                    </tr>            
                </tbody>
              </table>
      
            </div>
        </div>
    </div>
    
        
    
  </div>


</template>



<script>
import api from '@/core/services/ApiService'
import ActionsMenu from "@/components/dropdown/FaqListActions.vue";
import PackageActionToolbar from "@/components/actions/PackageToolbar.vue";
import FaqDetailForm from '@/components/modals/forms/EditFaq.vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";

const newFaqValues={
    answer:null,
    question:null,
    status:'AC',
    orderNo:null,
}

export default  {
  name:'PackageGroups',
  components:{
      ActionsMenu,
      FaqDetailForm,      
  },
  data(){
    
    return{        
        selectedFaq:newFaqValues,
        mode:'new',

      showDetail:false,
      
      statusses : {
        class:{
          'AC':'success',
          'DA':'danger'
        },
        label:{
          'AC':'Aktif',
          'DA':'Pasif'

        }
      },       
      faqs:null,
      

    }
  },

  

  methods:{
      editFaq(item){
          this.showDetail=true;
          this.mode='edit';
          this.selectedFaq = item;
      },

      cancelForm(){
          this.showDetail=false;
      },

      addFaq(){
          this.mode='new';
          this.selectedFaq = newFaqValues;        
          this.showDetail = true;

      },

      removeFaq(item){
        
        Swal.fire({
          icon:'warning',
          title:'Paket grubunu silmek üzeresiniz',
          text:item.groupName +' paket grubunu silmek istediğinize emin misiniz?\nBu işlemin geri dönüşü yoktur!',
          showCancelButton:true
        }).then((result)=>{
          if(result.isConfirmed){
            api.get('/Package/DeletePackageGroup/'+item.faqId).then((res)=>{
              Swal.fire({
                title:'Paket Grubu Silme',
                icon:'success',
                text:'Paket grubu silindi',
              }).then(()=>{
                window.location.reload();
              })
            });
          }else{
            
          }
        });
      },

      updateFaq(){
          let apiAddress = this.mode=='new' ? '/System/NewFAQ' : '/System/UpdateFAQ';

          api.post(apiAddress,this.selectedFaq).then((res)=>{
              Swal.fire({
                  text:'SSS Sorusu güncellendi',
                  icon:'success',
              }).then(()=>{
                  window.location.reload();
              });
          }).catch((err)=>{
              console.log(err);
          })
      }
  },


  created(){
      let aComponent = {component:PackageActionToolbar, props:{},events:['addPackage']};
     setCurrentPageBreadcrumbs("Sıkça Sorulan Sorular", ["Ayarlar","Tanımlamalar"]);
     api.get('/System/ListFAQs/JJ').then((res)=>{
         this.faqs = res.data;
     });
   
  },

}

</script>
<style scoped>
input:disabled{cursor: not-allowed; background-color: #eee;}
</style>