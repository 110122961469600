<template>
    <div>
        <div class="row">
            <div class="col-lg-9">
                <div class="row mb-10">
                    <div class="col-lg-12 fv-row">
                        <label class="col-form-label">Soru</label>                            
                        <input type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedFaq.question" />
                    </div>
                </div>

                <div class="row mb-10">
                    <div class="col-lg-12 fv-row">
                        <label class="col-form-label">Cevap</label>                            
                        <input type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedFaq.answer" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 fv-row">
                        <label class="col-form-label">Durum</label> 
                            <div class="d-flex align-items-center mt-3">
                            <label class="form-check form-check-inline form-check-solid me-5">
                                <input class="form-check-input" value="AC" name="status" :checked="selectedFaq.status == 'AC'" type="radio" v-model="selectedFaq.status" />
                                <span class="fw-bold ps-2 fs-6"> Aktif </span>
                            </label>

                            <label class="form-check form-check-inline form-check-solid">
                                <input class="form-check-input"  value="DA" name="status" :checked="selectedFaq.status == 'DA'" type="radio" v-model="selectedFaq.status" />
                                <span class="fw-bold ps-2 fs-6"> Pasif </span>
                            </label>
                        </div>
                    </div>

                    

                    <div class="col-lg-2 fv-row">
                        <label class="col-form-label">Sıra</label>                                         
                        <input type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedFaq.orderNo" />
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name:'EditPackage',
    props:['selectedFaq','mode','packageGroupId'],
   
        
}
</script>